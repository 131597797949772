import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Below is a selection of organizations whose work already reflects a commitment to improving the long-run future, through research, advocacy, entrepreneurship, policy advice, and more. This is not intended to be an exhaustive list, but rather a way to get a sense of the major organizations with a connection to the ideas of longtermism. Nor is this list claiming that everyone who works at these organizations would count themselves as 'longtermists' — just that their work looks especially good by longtermist lights.`}</p>
    <p>{`Note that these headers are not mutually exclusive — some of the 'advocacy' or 'philanthropy' organizations also conduct research, for instance. Note also that there are many other organizations which do not explicitly reflect a commitment to longtermism at the organizational level, but which are home to people who view their work through a longtermist perspective.`}</p>
    <h2 {...{
      "id": "research",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#research",
        "aria-label": "research permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><strong parentName="h2">{`Research`}</strong></h2>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://globalprioritiesinstitute.org/"
          }}><strong parentName="a">{`Global Priorities Institute`}</strong></a>{` at the University of Oxford (GPI) — conducts foundational academic research on questions about doing the most good.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://www.fhi.ox.ac.uk/"
          }}><strong parentName="a">{`Future of Humanity Institute`}</strong></a>{` at the University of Oxford (FHI) — a multidisciplinary research institute investigating big-picture questions about humanity and its prospects.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://www.openphilanthropy.org/our-global-health-and-wellbeing-and-longtermism-grantmaking-portfolios"
          }}><strong parentName="a">{`Open Philanthropy`}</strong></a>{` — a large research and grantmaking organisation which currently directs around half of its spending towards projects aimed at improving the long-term future.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://rethinkpriorities.org/longtermism"
          }}><strong parentName="a">{`Rethink Priorities`}</strong></a>{` —produces empirical research designed to guide charitable work. Their longtermist program focuses on forecasting, nuclear war, and existential risks.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://allfed.info/"
          }}><strong parentName="a">{`Alliance to Feed the Earth in Disasters`}</strong></a>{` (ALLFED) — Researches resilient food solutions and aims to help governments and companies implement them, to ensure people have enough to eat in the event of a global catastrophe.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://www.cser.ac.uk/"
          }}><strong parentName="a">{`Centre for the Study of Existential Risk`}</strong></a>{` at the University of Cambridge (CSER) — studies and offers policy advice on mitigating risks that could lead to human extinction or civilisational collapse.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "http://www.centerforhealthsecurity.org/"
          }}><strong parentName="a">{`Center for Health Security at Johns Hopkins`}</strong></a>{` — conducts research to protect people’s health from epidemics and other disasters.`}</p>
      </li>
    </ul>
    <h2 {...{
      "id": "advocacy",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#advocacy",
        "aria-label": "advocacy permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><strong parentName="h2">{`Advocacy`}</strong></h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.80000hours.com"
        }}><strong parentName="a">{`80,000 Hours`}</strong></a>{` — 80,000 Hours provides research and support to help students and recent  graduates switch into careers that effectively tackle the world’s most  pressing problems.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://futureoflife.org/"
        }}><strong parentName="a">{`Future of Life Institute`}</strong></a>{` (FLI) — works on reducing global catastrophic and existential risks from powerful technologies.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.forethought.org/longtermism"
        }}><strong parentName="a">{`Forethought Foundation`}</strong></a>{` — promotes academic work that addresses the question of how to use our scarce resources to improve the world by as much as possible.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://longnow.org/"
        }}><strong parentName="a">{`The Long Now Foundation`}</strong></a>{` — a nonprofit established to foster long-term thinking, at the timescale of the next and last 10,000 years.`}</li>
    </ul>
    <h2 {...{
      "id": "philanthropy",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#philanthropy",
        "aria-label": "philanthropy permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><strong parentName="h2">{`Philanthropy`}</strong></h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://openphilanthropy.org/"
        }}><strong parentName="a">{`Open Philanthropy`}</strong></a>{` — a large research and grantmaking organisation which currently directs around half of its spending towards projects aimed at improving the long-term future.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.longview.org/"
        }}><strong parentName="a">{`Longview Philanthropy`}</strong></a>{` — an organisation which helps connect philanthropists with opportunities to protect future generations.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://survivalandflourishing.fund/"
        }}><strong parentName="a">{`Survival and Flourishing Fund`}</strong></a>{` — a 'virtual fund' which helps donors decide where to make donations. Our goal is to bring financial support to organizations working to improve humanity’s long-term prospects for survival and flourishing. `}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://funds.effectivealtruism.org/funds/far-future"
        }}><strong parentName="a">{`The Long-Term Future Fund`}</strong></a>{`, part of `}<a parentName="li" {...{
          "href": "https://funds.effectivealtruism.org/"
        }}>{`Effective Altruism Funds`}</a>{` — makes small to medium grants to individuals and organizations that address global catastrophic risks or advocate for longtermist ideas.`}</li>
    </ul>
    <h2 {...{
      "id": "ai",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#ai",
        "aria-label": "ai permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><strong parentName="h2">{`AI`}</strong></h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://humancompatible.ai/"
        }}><strong parentName="a">{`Center for Human-Compatible AI (CHAI)`}</strong></a>{` — aims to conduct research to reorient the development of powerful AI towards provably beneficial systems.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.redwoodresearch.org/"
        }}><strong parentName="a">{`Redwood Research`}</strong></a>{` — conducts applied AI alignment research with a view to reducing long-term risks from powerful AI.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://intelligence.org/"
        }}><strong parentName="a">{`Machine Intelligence Research Institute (MIRI)`}</strong></a>{` — conducts foundational mathematical research to ensure smarter-than-human artificial intelligence has a positive impact.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://alignmentresearchcenter.org/"
        }}><strong parentName="a">{`Alignment Research Center`}</strong></a>{` — a non-profit research organization whose mission is to align future  machine learning systems with human interests. Its current work focuses  on developing an “end-to-end” alignment strategy that could be adopted  in industry today while scaling gracefully to future ML systems.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.governance.ai/"
        }}><strong parentName="a">{`Centre for the Governance of AI`}</strong></a>{` — building a global research community dedicated to helping humanity navigate the transition to a world with advanced AI.`}</li>
    </ul>
    <h2 {...{
      "id": "policy",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#policy",
        "aria-label": "policy permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><strong parentName="h2">{`Policy`}</strong></h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.longtermresilience.org/"
        }}><strong parentName="a">{`Centre on Long-Term Resilience`}</strong></a>{` — based in the UK, provides policy advice and support on the defining long-term challenges of our time, such as artificial intelligence, biosecurity, and risk management policy.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.simoninstitute.ch/"
        }}><strong parentName="a">{`Simon Institute for Longterm Governance`}</strong></a>{` — supports policymakers in cooperation with future generations, especially through mitigating extreme risks. Focus on international governance.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.againstpandemics.org/"
        }}><strong parentName="a">{`Guarding Against Pandemics (GAP)`}</strong></a>{` — advocates for public investments to prevent the next pandemic, including working with members of Congress to push for $30 billion in pandemic preparedness funding.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://nti.org"
        }}><strong parentName="a">{`Nuclear Threat Initiative (NTI)`}</strong></a>{` — works to prevent catastrophic accidents caused by the use of weapons of mass destruction.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      